import React, { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { datadogRum } from '@datadog/browser-rum';
import NdsThemeProviderPurple from '@ntuctech/devex-tangram/Theme/NdsThemeProviderPurple';
import dynamic from 'next/dynamic';

import ErrorBoundary from '../components/ErrorBoundary';
import { ERRORS_IGNORED_DATADOG } from '../services/api/constants';

const ErrorContent = dynamic(() => import('../components/ErrorBoundary/ErrorContent'));

datadogRum.init({
  applicationId: process.env.DATADOG_RUM_APP_ID,
  clientToken: process.env.DATADOG_RUM_CLIENT_TOKEN,
  site: 'datadoghq.com',
  service: 'ne-digital-single-account-management',
  env: process.env.DATADOG_RUM_ENV,
  version: process.env.VERSION,

  // https://docs.datadoghq.com/real_user_monitoring/browser/#browser-and-browser-premium-sampling-configuration
  // 20% of all sessions will be collected.
  sessionSampleRate: 20,
  // 95% of all sessions will be collected as Browser while 5% will be Browser Premium.
  sessionReplaySampleRate: 5,

  // https://docs.datadoghq.com/real_user_monitoring/frustration_signals/
  trackUserInteractions: true,
  trackFrustrations: true,

  // Do not send Datadog any telemetry.
  telemetrySampleRate: 0,

  // https://docs.datadoghq.com/real_user_monitoring/connect_rum_and_traces/?tab=browserrum#setup-rum
  // Allow these API requests outgoing to Next.js API to be traced.
  allowedTracingUrls: [
    /https:\/\/pass\.link\.sg/,
    /https:\/\/pass\.preprod\.link\.sg/,
    /http:\/\/localhost/,
    // Linkpass SDK API
    /https:\/\/api\.pass\.link\.sg/,
    /https:\/\/api\.pass\.preprod\.link\.sg/,
  ],
  // 100% of backend traces will be collected.
  // This is the default value but putting it here to be explicit.
  traceSampleRate: 100,

  defaultPrivacyLevel: 'mask',

  // Need this so Datadog won't complain when Next.js hot-reloads during development.
  silentMultipleInit: true,

  beforeSend: (event) => {
    let canEventBeDropped = false;

    // discard a RUM error event
    if (event.type === 'error') {
      canEventBeDropped = ERRORS_IGNORED_DATADOG.some((errorIgnorePattern) =>
        event.error.message.includes(errorIgnorePattern),
      );
    }

    // discard a RUM resource event
    if (event.type === 'resource') {
      if (
        event.resource.type === 'fetch' &&
        event.resource.url.includes('/api/authenticate') &&
        event.resource.status_code === 401
      ) {
        canEventBeDropped = true;
      }
    }

    if (canEventBeDropped) {
      return false;
    }
  },
});

datadogRum.startSessionReplayRecording();

function MyApp(props) {
  const { Component, pageProps } = props;

  useEffect(() => {
    TagManager.initialize({
      gtmId: process.env.GOOGLE_ANALYTICS_TRACKING_ID,
    });
  }, []);

  return (
    <NdsThemeProviderPurple>
      <ErrorBoundary fallback={<ErrorContent />}>
        <Component {...pageProps} />
      </ErrorBoundary>
    </NdsThemeProviderPurple>
  );
}

export default MyApp;
